<template>
  <footer>
    <div class="content has-text-centered">
      <p>
        <strong>mintert.net</strong> by
        <router-link :to="{ name: 'Home' }">Matthias Mintert</router-link
        >.<br />
        <router-link :to="{ name: 'Impressum' }">Impressum</router-link> &amp;
        <router-link :to="{ name: 'Datenschutz' }">Datenschutz</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
