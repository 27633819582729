<template>
  <div class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <figure class="image">
          <img
            alt="mintert.net logo"
            src="../assets/binary_mintert_rund.svg"
            style="width: 32em; height: 32em;"
          />
        </figure>
        <h1 class="title">
          Matthias Mintert
        </h1>
        <h2 class="subtitle">
          Systemadministration
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.container {
  text-align: center;
}

img {
  margin: 0 auto;
  margin-top: -14em;
  margin-bottom: 2em;
  width: 32em;
  height: 32em;
}
</style>
