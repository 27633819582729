<template>
  <nav class="navbar is-transparent is-fixed-top">
    <div class="container">
      <div class="navbar-brand">
        <router-link
          class="navbar-item"
          :to="{ name: 'Home' }"
          v-bind:class="{ 'is-active': isOpen }"
        >
          <img
            src="@/assets/MM_logo_nav2.png"
            alt="Bulma: a modern CSS framework based on Flexbox"
            width="43"
            height="30"
          />
        </router-link>

        <div
          class="navbar-burger burger"
          data-target="navbarExampleTransparentExample"
          @click="isOpen = !isOpen"
          v-bind:class="{ 'is-active': isOpen }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div
        id="navbarExampleTransparentExample"
        class="navbar-menu"
        v-bind:class="{ 'is-active': isOpen }"
        @click="isOpen = !isOpen"
      >
        <div class="navbar-start">
          <router-link class="navbar-item" :to="{ name: 'Home' }"
            >Home</router-link
          >
          <router-link class="navbar-item" :to="{ name: 'About' }"
            >About</router-link
          >
        </div>

        <!--         <div class="navbar-end">
          <div class="navbar-item">
            <div class="field is-grouped">
              <p class="control">
                <a
                  class="bd-tw-button button"
                  data-social-network="Twitter"
                  data-social-action="tweet"
                  data-social-target="https://bulma.io"
                  target="_blank"
                  href="https://twitter.com/intent/tweet?text=Bulma: a modern CSS framework based on Flexbox&amp;hashtags=bulmaio&amp;url=https://bulma.io&amp;via=jgthms"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                  </span>
                  <span>
                    Tweet
                  </span>
                </a>
              </p>
              <p class="control">
                <a
                  class="button is-primary"
                  href="https://github.com/jgthms/bulma/releases/download/0.9.1/bulma-0.9.1.zip"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </span>
                  <span>Download</span>
                </a>
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Navbar",
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
};
</script>
<style lang="scss">
nav {
  font-family: "Syne Mono", monospace;
}
</style>
