import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faVuejs,
  faDocker,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

require("@/assets/main.scss");

library.add(faCoffee, faDownload, faTwitter, faVuejs, faDocker);

createApp(App)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
