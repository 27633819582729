<template>
  <div class="container">
    <transition name="fade" appear>
      <Navbar />
    </transition>
  </div>
  <section class="section">
    <div class="container">
      <router-view v-slot="{ Component }">
        <transition name="fadeView">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <Footer />
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  text-align: center;
}

/* navigation menu fade in */
.fade-enter,
.fade-enter-from {
  opacity: 0;
}
.fade-leave,
.fade-leave-to,
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease-in;
}

/* router view fade in */
.fadeView-enter,
.fadeView-leave-to {
  opacity: 0;
}
.fadeView-enter-active,
.fadeView-leave-active {
  transition: opacity 0.3s ease-in;
}

/* router view slide in transition */
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}
/* router view animation */
.moveUp-enter-active {
  animation: fadeIn 0.5s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.moveUp-leave-active {
  animation: moveUp 0.3s ease-in;
}
@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-400px);
  }
}
</style>
